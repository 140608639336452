import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css'
import "./src/styles/global.scss";
import {I18nextProvider} from "react-i18next";
import  i18n from "./i18n";
import React from 'react'

export const wrapRootElement = ({element}) => {
    return <I18nextProvider i18n={i18n}>
        {element}
    </I18nextProvider>
}
