exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-work-cnk-tsx": () => import("./../../../src/pages/our-work/cnk.tsx" /* webpackChunkName: "component---src-pages-our-work-cnk-tsx" */),
  "component---src-pages-our-work-gwexperts-tsx": () => import("./../../../src/pages/our-work/gwexperts.tsx" /* webpackChunkName: "component---src-pages-our-work-gwexperts-tsx" */),
  "component---src-pages-our-work-nestle-tsx": () => import("./../../../src/pages/our-work/nestle.tsx" /* webpackChunkName: "component---src-pages-our-work-nestle-tsx" */),
  "component---src-pages-our-work-sowaas-tsx": () => import("./../../../src/pages/our-work/sowaas.tsx" /* webpackChunkName: "component---src-pages-our-work-sowaas-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-portfolio-template-js-content-file-path-src-content-portfolio-example-project-1-index-mdx": () => import("./../../../src/templates/portfolioTemplate.js?__contentFilePath=/opt/buildhome/repo/src/content/portfolio/exampleProject1/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-template-js-content-file-path-src-content-portfolio-example-project-1-index-mdx" */),
  "component---src-templates-portfolio-template-js-content-file-path-src-content-portfolio-example-project-2-index-mdx": () => import("./../../../src/templates/portfolioTemplate.js?__contentFilePath=/opt/buildhome/repo/src/content/portfolio/exampleProject2/index.mdx" /* webpackChunkName: "component---src-templates-portfolio-template-js-content-file-path-src-content-portfolio-example-project-2-index-mdx" */)
}

